import React from 'react'
import DatenschutzText from '../components/datenschutz'
import Layout from "../components/layout";
import Helmet from "react-helmet";

const Impressum = () => (

    <Layout>

        <Helmet>
            <meta property="robots" content='noindex'/>
        </Helmet>

        <div className={"py-10 max-w-xl mx-auto px-10 sm:px-0"}>
            <DatenschutzText/>

            <div className={'mt-20'}>
                <a className="rounded bg-primary-500 text-white px-4 py-2" href="/"
                >Zurück zur Homepage</a>
            </div>
        </div>

    </Layout>
)

export default Impressum
